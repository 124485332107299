<template>
  <div class="bg">
    <div class="video-background">
      <div class="overlay">
      </div>
    </div>
    <v-form class="pt-16">
      <v-row class="justify-center">
        <v-col cols="12" md="6" sm="8">
          <v-card elevation="1" class="mx-auto rounded-lg">
            <v-row class="justify-center">
              <v-col cols="12" md="10" sm="10" class="pb-16 px-8">
                <div class="d-flex flex-column mt-4 font-weight-bold subtitle-1 text-center">
                  MASUKKAN PASSWORD BARU
                </div>
                <v-divider></v-divider>
                <div class="mt-4 mx-4 subtitle-2 font-weight-light text-center">
                  Bapak/Ibu, [Nama User] silahkan masukkan password baru anda.
                  <span class="red--text">
                  Jangan Melakukan Refresh Browser
                  </span>
                </div>


                <div class="otp-container mt-8 flex-column">
                  <div class="password-wrapper">
                    <input :type="showPassword ? 'text' : 'password'"
                           v-model="user.password"
                           class="otp-input"
                           placeholder="Password Baru">
                    <v-icon @click="togglePasswordVisibility" class="eye-icon">{{ showPassword ? 'mdi-eye-off' : 'mdi-eye' }}</v-icon>
                  </div>
                  <div class="password-wrapper mt-4">
                    <input :type="showRetypePassword ? 'text' : 'password'"
                           v-model="user.retypepassword"
                           class="otp-input"
                           placeholder="Tulis Ulang">
                    <v-icon @click="toggleRetypePasswordVisibility" class="eye-icon">{{ showRetypePassword ? 'mdi-eye-off' : 'mdi-eye' }}</v-icon>
                  </div>
                  <div v-if="user.retypepassword">
                    <div v-if="!isMatchPassword && user.retypepassword.length>2" class="caption red--text">
                      Password tidak match
                    </div>
                  </div>
                </div>
                <div class="text-center">
                  <div v-if="msgSuccess" class="subtitle-1 font-weight-bold green--text">
                    {{ msgSuccess }}
                    <span>
                    <router-link to="/login">
                      <span class="blue--text ml-1 subtitle-1">Login</span>
                    </router-link>
                    </span>
                  </div>

                </div>

                <div class="text-center mt-10">
                  <v-btn
                      v-if="! msgSuccess"
                      :disabled="! isMatchPassword "
                      color="primary" class="rounded-lg"
                      @click="handChangePassword" width="200px">
                      Ganti Password
                  </v-btn>
                </div>


                <v-divider class="mt-10"></v-divider>
                <div class="text-center grey--text mt-2 subtitle-2">
                  BKPSDM KOTA PALANGKARAYA 2024
                </div>

              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import User from "@/models/user";
import UserService from "@/services/user-service";
import AuthService from "@/services/auth-service";


export default {
  name: "ResetNewPasswordView",
  components: {},
  data() {
    return {
      user: new User("", ""),

      showPassword: false,
      showRetypePassword: false,

      msgSuccess: "",
    };
  },
  computed: {
    isMatchPassword() {
      return this.user.password === this.user.retypepassword;
    },
    whatsAppNumber() {
      return this.$store.state.registration.whatsAppNumber;
    }
  },
  watch: {
    whatsAppNumber: {
      handler: function (value) {
        if (value){
          this.initializeData(value)
        }
      },
      // deep: true
    }
  },
  created() {
  },
  methods: {
    initializeData(whatsAppNumber){
      console.log("=============== whatsappNumber request ===============")
      UserService.getIdByWhatsappNumber(whatsAppNumber).then(response => {
        // console.log(JSON.stringify(response.data))
        this.user = response.data
      })
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    toggleRetypePasswordVisibility() {
      this.showRetypePassword = !this.showRetypePassword;
    },
    handChangePassword() {

         // const whatsAppNumber  = '082143574692';
        const whatsAppNumber = this.whatsAppNumber
        UserService.getIdByWhatsappNumber(parseInt(whatsAppNumber)).then(response => {

          const resUser = response.data
          resUser.password = this.user.password


          AuthService.updateUserPassword(resUser).then(() => {
            this.msgSuccess = "Password berhasil diubah, Silahkan"
          })

        })
    },

  },
  mounted() {
  },
};
</script>
<style scoped>
.overlay {
  position: absolute;
  background-position: center;
  background-color: #ecf0f4;
  width: 100%;
  height: 100vh;
  z-index: 1;
  opacity: 0.99;
  background-size: contain;
}
.video-background {
  background-repeat: no-repeat; /* Optional: Do not repeat the image */
  background-size: cover; /* Optional: Cover the entire container */
  background-position: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -2;
}
.bg {
  background: black;
  width: 100%;
  height: 100vh;
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
}


.otp-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25vh;
  background-color: #f0f8ff;
  flex-direction: column;
}
.password-wrapper {
  display: flex;
  align-items: center;
  position: relative;
}
.otp-input {
  width: 300px;
  padding: 10px 15px;
  font-size: 14px;
  border: 2px solid #ccc;
  border-radius: 8px;
  transition: all 0.3s ease;
  text-align: center;
  letter-spacing: 2px;
}
.otp-input:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 10px rgba(0, 123, 255, 0.5);
}
.eye-icon {
  position: absolute;
  right: 15px;
  cursor: pointer;
}
</style>


